// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"colors-primary": "#516994",
	"colors-success": "#28c76f",
	"colors-danger": "#ea5455",
	"colors-warning": "#ffba00",
	"colors-dark": "#1e1e1e",
	"colors-grey-dark": "#56595c",
	"colors-grey": "#b8c2cc",
	"colors-grey-light": "#dae1e7",
	"colors-white": "#ffffff",
	"colors-black": "#22292f",
	"colors-invisible": "rgba(0, 0, 0, 0)",
	"colors-benchmark": "#8d8c8c",
	"colors-benchmark-dark": "#5f5f5f",
	"colors-live": "#c7650a",
	"misc-border-radius-px": "5px",
	"typography-font-family-sans-serif": "Helvetica, sans-serif"
};
export default ___CSS_LOADER_EXPORT___;
